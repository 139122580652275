import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class AdminService {
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {
  }

  getUsers(token){
    return this
      .http
      .get('api/firebase/listUsers/'+token);
  }

}
