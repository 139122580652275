import { Component, OnInit } from '@angular/core';
import {FournisseurService} from "../fournisseurs/fournisseur.service";
import {Fournisseur} from "../fournisseurs/fournisseur.interface";

@Component({
  selector: 'app-ajout-fournisseur',
  templateUrl: './ajout-fournisseur.component.html',
  styleUrls: ['./ajout-fournisseur.component.css']
})
export class AjoutFournisseurComponent implements OnInit {

  fournisseurs: Fournisseur = {
    code: '',
    nom: ''
  };

  constructor(private fournisseurService: FournisseurService) { }

  ngOnInit() {

  }

  createFournisseur(data: Fournisseur){
    this.fournisseurService.createFournisseur(data);
  }
}
