import { Component, OnInit,ViewChild, ElementRef,AfterViewInit} from "@angular/core";
import {MachineService} from './machine.service';
import {Machine} from './machine.interface';
import {Router} from "@angular/router";
import {MatPaginator, MatSort, MatTableDataSource, MatTableModule} from "@angular/material";

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})

export class MachinesComponent implements OnInit {

  machines: Machine[];
  displayedColumns = ["SN_MACHINES", "NomFournisseur_PEINTURES","Nom_CLIENTS","DateMiseService_MACHINES","DETAILS","EDIT","DELETE"];
  dataSource: MatTableDataSource<Machine>;
  nbElem: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(private machineService: MachineService,public router: Router) {

  }


  ngOnInit(): void {
  this.machineService.getMachine().subscribe((data: Machine[]) =>{
    this.dataSource = new MatTableDataSource(data);
    console.log(this.dataSource);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.nbElem = data.length;
    });

  console.log(this.dataSource);

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit(){



  }

  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
   // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  }

  goToAddMachine () {
    this.router.navigateByUrl('/ajout-machine');
  }

  deleteMachine(data: Machine) {
    this.machineService.deleteMachine(data);
    this.ngOnInit();
  }

  detailsMachine(row) {
    console.log('Row clicked: ', row);
    this.router.navigate(['/machine/',row.SN_MACHINES]);
  }

  countMachine() {
   return this.machineService.countMachine();
  }

  editMachine(machine) {
    console.log(machine);
    this.router.navigate(['/edit-machine/',machine.SN_MACHINES]);
  }

}


