import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientService} from './client.service';
import {Client} from './client.interface';
import {Router} from "@angular/router";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clients: Client[];
  dataSource: MatTableDataSource<Client>;
  displayedColumns = ["ID","Nom","Adresse","EDIT","DELETE"]
  nbElem: number;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private clientService: ClientService, private router: Router) { }

  ngOnInit() {
    this.clientService
      .getClient()
      .subscribe((data: Client[]) =>{
        this.dataSource =new MatTableDataSource<Client>(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.nbElem = data.length;
      });
    console.log(this.clients);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  }
  goToAddClient (){
    this.router.navigateByUrl('/ajout-client');
  }

  deleteClient(data: Client) {
    this.clientService.deleteClient(data);
    this.ngOnInit();
  }
}
