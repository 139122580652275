import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LayoutModule} from '@angular/cdk/layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule, MatTabsModule,
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from "@angular/common/http";
import {MachinesComponent} from './machines/machines.component';
import {MachineService} from "./machines/machine.service";
import {ToastrModule} from "ngx-toastr";
import { AjoutMachineComponent } from './ajout-machine/ajout-machine.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
import { ClientsComponent } from './clients/clients.component';
import {ClientService} from "./clients/client.service";
import { AjoutClientComponent } from './ajout-client/ajout-client.component';
import { FournisseursComponent } from './fournisseurs/fournisseurs.component';
import {FournisseurService} from "./fournisseurs/fournisseur.service";
import { AjoutFournisseurComponent } from './ajout-fournisseur/ajout-fournisseur.component';
import {GoogleChartsModule} from "angular-google-charts";
import { ChartsComponent } from './charts/charts.component';
import { ChartsModule } from 'ng2-charts';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import { MachinesDetailsComponent } from './machines-details/machines-details.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { EditMachineComponent } from './edit-machine/edit-machine.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MomentDateModule} from "@angular/material-moment-adapter";
import {MatTableExporterModule} from "mat-table-exporter";
import {CdkTableExporterModule} from "cdk-table-exporter";
import { EditClientComponent } from './edit-client/edit-client.component';
import {SampleModule} from "angular-pdf-generator";
import { LoginComponent } from './login/login.component';
import {LoggedInGuard, NgxAuthFirebaseUIModule} from "ngx-auth-firebaseui";
import {environment} from "../environments/environment";
import {AngularFireAuthGuard, customClaims, hasCustomClaim, loggedIn} from "@angular/fire/auth-guard";
import { AdminComponent } from './admin/admin.component';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {IsAdminGuard} from "./is-admin.guard";
import {pipe} from "rxjs";
import {map} from "rxjs/operators";
import { LumiereComponent } from './lumiere/lumiere.component';
import { LumiereService} from "./lumiere/lumiere.service";
import {NgToggleModule} from "@nth-cloud/ng-toggle";
import { LumiereMcbaComponent } from './lumiere-mcba/lumiere-mcba.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
const adminOnly = hasCustomClaim('admin');
const appRoutes: Routes = [
  {path: 'machine', component: MachinesComponent, canActivate: [LoggedInGuard]},
  {path: 'machine/:sn', component: MachinesDetailsComponent, canActivate: [LoggedInGuard]},
  {path: 'ajout-machine', component: AjoutMachineComponent, canActivate: [LoggedInGuard] },
  {path: 'edit-machine/:sn', component: EditMachineComponent, canActivate: [LoggedInGuard]},
  {path: 'client', component:ClientsComponent, canActivate: [LoggedInGuard]},
  {path: 'ajout-client', component: AjoutClientComponent, canActivate: [LoggedInGuard] },
  {path: 'fournisseur', component: FournisseursComponent, canActivate: [LoggedInGuard]},
  {path: 'ajout-fournisseur', component: AjoutFournisseurComponent, canActivate: [LoggedInGuard] },
  {path: 'chart', component: ChartsComponent, canActivate: [LoggedInGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [LoggedInGuard]},
  {path: 'lumiere', component: LumiereComponent, canActivate: [LoggedInGuard]},
  {path: 'lumiere/:mcba', component: LumiereMcbaComponent, canActivate: [LoggedInGuard]},
  {path: 'login', component: LoginComponent},
  {path: '', component: LoginComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MachinesComponent,
    AjoutMachineComponent,
    ClientsComponent,
    AjoutClientComponent,
    FournisseursComponent,
    AjoutFournisseurComponent,
    ChartsComponent,
    MachinesDetailsComponent,
    EditMachineComponent,
    EditClientComponent,
    LoginComponent,
    AdminComponent,
    LumiereComponent,
    LumiereMcbaComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot(),
    FormsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' //set defaults here
    }),
    MatSelectModule,
    GoogleChartsModule.forRoot(),
    ChartsModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    CdkTableExporterModule,
    SampleModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebase),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatTabsModule,
    NgToggleModule,
  ],
  providers: [
    MachineService,
    ClientService,
    FournisseurService,
    LumiereService,
    AngularFireAuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
