import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MachineService} from "../machines/machine.service";
import {Machine} from '../machines/machine.interface';
import {ActivatedRoute, Router} from "@angular/router";
import * as Chart from "chart.js";
import 'chartjs-plugin-colorschemes';
import {map} from "rxjs/operators";
import html2canvas from "html2canvas";
import {MatDatepickerInputEvent} from "@angular/material";
import * as jspdf from 'jspdf';
import {Errors, Nexus} from "../machines-details/machines-details.component";
import {FormControl} from "@angular/forms";
import {of} from "rxjs";
import {tmpdir, type} from "os";

import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';




export class doorWarning {
  sn: string;
  message: string;
}

export class fdoor {
  Value_CAPTEURS: number;
  SN_MACHINES: string;
  Time_NEXUS: Date;
}

@Component({
  selector: 'charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})

export class ChartsComponent implements OnInit {

 public fdoor: Array<fdoor> = new Array<fdoor>();
 doorWarning: Array<doorWarning> = new Array<doorWarning>();
 chart: any = [];
  SN: Array<string> = new Array<string>();
 date1 = new Date();
 date2 = new Date();
 test: any;
 data = {'sn':null, 'd1':null,'d2':null};

  control1 = new FormControl(this.date1);

  control2 = new FormControl(this.date2);

  constructor(public router: Router,public machineService: MachineService) { }



  ngOnInit() {
    this.date2.setDate(this.date1.getDate()-7);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.drawPaintingLigneChart();
    this.getMachineArray();
    this.getFrontDoorSensorsAsync();

}

 drawPaintingLigneChart(){

   this.machineService.countByPaint()
     .subscribe(result => {
       console.log(result);
       let paints = result['rows'].map(res => res.NomFournisseur_PEINTURES);
       console.log(paints);
       let count = result['rows'].map(res => res.Count);
       console.log(count);
       this.chart = new Chart('canvas',{
         type: 'pie',
         data: {
           labels: paints,
           datasets: [
             {
               data: count

             },
           ]
         },
         options: {
           legend: {
             display: false,
             position: 'bottom'
           },
           scales: {
             xAxes: [{
               display: false
             }],
             yAxes: [{
               display: false
             }]
           },
           plugins: {
             colorschemes: {
               scheme: 'brewer.SetOne9'
             }
           },
         }
       });
     });
 }

    getMachineArray(){
    //this.SN= new Array<string>();
    this.machineService.getMachine().subscribe((result: Machine[])=>{
     // console.log(result);
      // @ts-ignore
     let tmp = result.map( res => res.SN_MACHINES);
      for(let i=0; i<tmp.length; i++){
        this.SN.push(tmp[i]);
      }

    });
  }



 async getFrontDoorSensorsAsync() {
    this.doorWarning = [];
     for (let j = 0; j < this.SN.length; j++) {
       this.data.sn = this.SN[j];
       const res = await this.machineService.getFrontDoorSensors(this.data);
        res.subscribe((result: fdoor[])=>{
          this.fdoor=result['fdoor'];
       },error => console.error("Error while geting front door data"),()=>this.showMachineWithAnOppenedDoor())
       //this.fdoor[j] = data;
     }
   }


 showMachineWithAnOppenedDoor(){
          if(this.fdoor.length > 0){
        let cpt=0;
        let dateDebut;
        let dateFin;
        for(let j=0; j<(this.fdoor.length); j++){
          //console.log("Sensore value of nbr : ", j, this.fdoor[j].Value_CAPTEURS);
          if(this.fdoor[j].Value_CAPTEURS==0){
            cpt++; dateDebut = this.fdoor[j].Time_NEXUS;
            //console.log(cpt,dateDebut);
          }
          else{
            if(cpt>=3){
              dateFin=this.fdoor[j-1].Time_NEXUS;
              //console.log("La porte frontale de la machine : "+this.fdoor[j-1].SN_MACHINES+", est restée ouverte plus de 45min. De "+this.getDateTime(dateDebut)+" à "+this.getDateTime(dateFin)+" ! ");
             let dw = new doorWarning(); dw.sn=this.fdoor[j-1].SN_MACHINES; dw.message = "La porte frontale de la machine est restée ouverte plus de 45min. De "+this.getDateTime(dateDebut)+" à "+this.getDateTime(dateFin)+" ! ";
              this.doorWarning.push(dw);
              console.log(this.doorWarning);
            }
            cpt=0;
            dateFin=null;
            dateDebut=null;
          }
        }
      }
  };

  addEvent1( event: MatDatepickerInputEvent<Date>) {
    this.date1=event.value;
    // console.log(this.date1);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.getFrontDoorSensorsAsync();
  };

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.date2=event.value;
    //console.log(this.date2);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
   this.getFrontDoorSensorsAsync();
  };

  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  };

  getDateTime(string){
    let d1 = new Date(string).toLocaleTimeString();
    let d2 = new Date(string).toLocaleDateString();
    let str =d2+" "+d1;
    return str;
  };

  public captureScreen() {
    const data = document.getElementById('pdf');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(this.getDate(0)+'_overview_'+'.pdf');
    });
  };
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
