import { Component, OnInit } from '@angular/core';
import {ClientService} from '../clients/client.service';
import {Client} from "../clients/client.interface";

@Component({
  selector: 'app-ajout-client',
  templateUrl: './ajout-client.component.html',
  styleUrls: ['./ajout-client.component.css']
})
export class AjoutClientComponent implements OnInit {

  clients: Client = {
    ID_CLIENTS: null,
    Adresse_CLIENTS: '',
    Nom_CLIENTS: ''
  };

  constructor(private clientService: ClientService) { }

  ngOnInit() {
  }

  createClient(data: Client) {
    this.clientService.createClient(data);
  }

}
