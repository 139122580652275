import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";

@Injectable()
export class ClientService {
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) {
  }

  url = '/api';

  getClient() {
    return this
      .http
      .get(`${this.url}/clients`);
  }

  createClient(data) {
    this.http.post(`${this.url}/clients`, data)
      .subscribe(
        res => {
          console.log(res);
          this.toastr.success('Votre client a été créer avec succès.', 'Success');
          this.router.navigateByUrl('/client');
        },
        err => {
          console.log('Error occured:', err);
          this.toastr.error(err.message, 'Error occured');
        }
      );
  }

  deleteClient(data) {
    this.http.post(`${this.url}/clients/delete`, data)
      .subscribe(
        res => {
          console.log(res);
          this.toastr.success('Le client a été supprimer avec succès.', 'Success');
          this.getClient();
        },
        err => {
          console.log('Error occured:', err);
          this.toastr.error(err.message, 'Error occured');

        }
      );
  }
}
