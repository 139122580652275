import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from './admin.service';
import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import {FirebaseAuth} from "@angular/fire";
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/firestore";
import {User} from "./user";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {switchMap} from "rxjs/operators";
import {MatTableDataSource, MatPaginator, MatSort} from "@angular/material";
import {async} from "q";

var firebaseConfig = {
  apiKey: "AIzaSyB4NOIvRHAPGOG3yHp4BY1yLRa71sBkHlw",
  authDomain: "ft-minority.firebaseapp.com",
  databaseURL: "https://ft-minority.firebaseio.com",
  projectId: "ft-minority",
  storageBucket: "ft-minority.appspot.com",
  messagingSenderId: "465749893118",
  appId: "1:465749893118:web:eafb64d89e6a8f17"
}

firebase.initializeApp(firebaseConfig);

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [AdminService]
})
export class AdminComponent implements OnInit {

  users: any ={
    email: '',
    password: '',
  };
  app2 = firebase.initializeApp(environment.firebase,'tempApp');
  displayedColumns = ["uid","name", "email", "auth"];
  dataSource: MatTableDataSource<any>;
  idToken: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, public router: Router, private adminService: AdminService) { }

  async ngOnInit() {
    this.users.password = Math.random().toString(20).substr(2, 17);
    this.idToken = await firebase.auth().currentUser.getIdToken();
    console.log(this.idToken);

    this.adminService.getUsers(this.idToken).subscribe((data: any) => {
      console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }
 /****************************************************************************************************************************************
 THIS WAS NOT DONE THE RIGHT WAY, ADDING USERS TROUGH THE CLIENT API WAS NOT THE INTENDED USE. SHOULD IMPLEMENT THE ADMIN API WITH NODE.JS
  ****************************************************************************************************************************************/
  SignUp(email, password){


    return this.app2.auth().createUserWithEmailAndPassword(email, password)
      .then((result)=> {
        this.SendVerificationMail();
        this.SetUserData(result.user);
        this.ForgotPassword(email);
      }).catch((error)=> {
        window.alert(error.message)
      })

  }

  SendVerificationMail() {
    return this.app2.auth().currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  ForgotPassword(passwordResetEmail) {
    return this.app2.auth().sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  async getIdToken(){
    firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
      //console.log(idToken);

    }).catch(function(error) {
      console.log("Error");
    });
    /*
    firebase.auth().currentUser.getIdTokenResult(true).then((idTokenResult)=>{
      console.log('is admin : ',!!idTokenResult.claims.admin);
      if(!idTokenResult.claims.admin)
        this.router.navigateByUrl('/');
  })*/
  }


}
