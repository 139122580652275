import { Component, OnInit, OnChanges, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MachineService} from "../machines/machine.service";
import * as Chart from "chart.js";
import 'chartjs-plugin-colorschemes';
import {FormControl} from "@angular/forms";
import {MatDatepickerInputEvent, MatNativeDateModule, MatSort, MatTableDataSource} from "@angular/material";
import {ToastrService} from "ngx-toastr";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import wkhtmltopdf from 'wkhtmltopdf';




export interface Errors {
  ID_ERRORS: bigint;
  Name_ERRORS: string;
  Date_ERRORS: Date;
  SN_MACHINES: string;
  Code_ERRORS: string;
}

export interface Nexus {
    Time_NEXUS: Date;
    PIPrestart_NEXUS: Date;
    DWWIrestart_NEXUS: Date;
    Autotest_NEXUS: Date;
}
export class Sensors {
  lib: string;
  values: number[];
}



@Component({
  selector: 'app-machines-details',
  templateUrl: './machines-details.component.html',
  styleUrls: ['./machines-details.component.css']
})




export class MachinesDetailsComponent implements OnInit {
  sn: string;
  errors: Errors[];
  nexus: Nexus[];
  chart: any = [];
  date1 = new Date();
  date2 = new Date();
  control1 = new FormControl(this.date1);
  control2 = new FormControl(this.date2);
  data = {'sn':null, 'd1':null,'d2':null};
  sensors: Sensors[]=[];
  dates: any[]=[];
  datesShortened: any[];


  constructor(private route: ActivatedRoute, private toastr: ToastrService, public router: Router, private machineService: MachineService) { }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.sn = params.get("sn")
    });
    this.date2.setDate(this.date1.getDate()-7);
    this.data.sn=this.sn;this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();

      this.machineService.errorBySN(this.data).subscribe((data: Errors[]) =>{
        this.errors = data;
       // console.log(data);
      });
      this.machineService.nexusBySN(this.data).subscribe((data: Nexus[]) =>{
        this.nexus = data['nexus'];
      });
      console.log(this.nexus);
    //console.log(this.errors);
    this.machineService.errorByDate(this.sn).subscribe(result => {
    //  console.log(result);
    let error = result['detail'].map(res => res.Count);
     // console.log(error);
      let dates = result['detail'].map(res => new Date(res.Dates).toLocaleDateString("fr-FR"));
      //console.log(dates);
      this.chart = new Chart('canvas', {
        type: 'bar',
        data: {
          labels: dates,
          datasets: [
            {
              label: '# of errors',
              data:error
            },
          ]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true
            }],
            yAxes: [{
              display: true
            }]
          },
          plugins: {
            colorschemes: {
              scheme: 'brewer.SetOne9'
            }
          },
        }
      })
    } );

    this.getSensors();
  }

  ngOnChanges(){
    this.toastr.clear();
  }

getSensors(){
  this.toastrClear();
  this.machineService.sensors(this.data).subscribe(result =>{
    let libelle = result['sensor'].map(res => res.Libelle_CAPTEURS).slice(0,20);
    //console.log(libelle);
    let state = result['sensor'].map(res => res.Value_CAPTEURS);
   // console.log(state);
    let date = result['sensor'].map(res => res.Time_NEXUS);
   // console.log(date);

    //console.log(this.sensors);

    var tmp=[];
    this.dates = [];
    for(let i=0; i<libelle.length; i++){
      this.sensors[i]= new Sensors();
      this.sensors[i].lib=libelle[i];

      var t=[];

      for(let j=i; j<state.length; j=j+20){
        t.push(state[j]);
        this.dates.push(date[j]);
      }
      this.sensors[i].values=t;
      tmp[i]=t;
    }
  this.datesShortened=this.dates.slice(0,(state.length/libelle.length));
    //console.log(this.sensors,this.dates);
    this.frontDoorWasOpenWarning();
    this.lateralDoorWasOpenWarning();
  });


}

  addEvent1( event: MatDatepickerInputEvent<Date>) {
    this.date1=event.value;
   // console.log(this.date1);
    this.data.sn=this.sn;this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.machineService.errorBySN(this.data).subscribe((data: Errors[]) =>{
      this.errors = data;
      console.log(data);
    });
    this.machineService.nexusBySN(this.data).subscribe((data: Nexus[]) =>{
      this.nexus = data['nexus'];
      console.log(data);
    });
   this.getSensors();
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.date2=event.value;
    //console.log(this.date2);
    this.data.sn=this.sn;this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.machineService.errorBySN(this.data).subscribe((data: Errors[]) =>{
      this.errors = data;
      console.log(data);
    });
    this.machineService.nexusBySN(this.data).subscribe((data: Nexus[]) =>{
      this.nexus = data['nexus'];
      console.log(data);
    });
    console.log(this.nexus);
    this.getSensors();

  }
  toastrClear(){
    this.toastr.clear();
  }
  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  }
  getDateTime(string){
    let d1 = new Date(string).toLocaleTimeString();
      let d2 = new Date(string).toLocaleDateString();
    let str =d2+" "+d1;
    return str;
  }
  frontDoorWasOpenWarning(){
    //console.log("DWOW in");
    if(this.dates.length==0) return 0;
    console.log("DWOW in");
    let cpt=0;
    let dateDebut;
    let dateFin;
    for(let i=0; i<(this.dates.length/20); i++){
      //console.log(i, this.sensors[11].values[i]);
      if(this.sensors[11].values[i]==0){
        cpt++; dateDebut = this.dates[i];
        //console.log(cpt,dateDebut);
      }
      else{
        if(cpt>=3){
          dateFin=this.dates[i-1];
          this.toastr.error("La porte frontale est restée ouverte plus de 45min. De "+this.getDateTime(dateDebut)+" à "+this.getDateTime(dateFin)+" ! ","WARNING !", {disableTimeOut: true});
        }
        cpt=0;
        dateFin=null;
        dateDebut=null;
      }
    }

  }
  lateralDoorWasOpenWarning(){
    //console.log("DWOW in");
    if(this.dates.length==0) return 0;
    console.log("DWOW in")

    let cpt=0;
    let dateDebut;
    let dateFin;
    for(let i=0; i<(this.dates.length/20); i++){
     // console.log(i, this.sensors[12].values[i]);
      if(this.sensors[12].values[i]==0){
        cpt++; dateDebut = this.dates[i];
        //console.log(cpt,dateDebut);
      }
      else{
        if(cpt>=3){
          dateFin=this.dates[i-1];
          this.toastr.error("La porte laterale est restée ouverte plus de 45min. De "+this.getDateTime(dateDebut)+" à "+this.getDateTime(dateFin)+" ! ","WARNING !", {disableTimeOut: true});
        }
        cpt=0;
        dateFin=null;
        dateDebut=null;
      }
    }
  }

  public captureScreen() {
    const data = document.getElementById('pdf');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(this.getDate(0)+'_detail_'+this.sn+'.pdf');
    });
  }

  print(){
    window.print();
  }
/*

  toPDF(){
    const PDFDocument = require('wkhtmltopdf');
  PDFDocument(document.getElementById('pdf'),{ output: 'out.pdf', child_process: false});

  }*/
}
