import { Component, OnInit } from '@angular/core';
import {MachineService} from "../machines/machine.service";
import {Machine} from "../machines/machine.interface";
import {ClientService} from "../clients/client.service";
import {Client} from "../clients/client.interface";
import {FournisseurService} from "../fournisseurs/fournisseur.service";


export interface Code {
  MCBA_PEINTURES: string;
  NomFournisseur_PEINTURES: string;
}

@Component({
  selector: 'app-ajout-machine',
  templateUrl: './ajout-machine.component.html',
  styleUrls: ['./ajout-machine.component.css']
})
export class AjoutMachineComponent implements OnInit {

  machines: Machine = {
    sn: '',
    nomclient: '',
   date: '',
    fournisseur: '',

  };

  clients: Client[];

  codes: Code[];

  constructor(private machineService: MachineService, private clientService: ClientService, private fournisseurService: FournisseurService) { }

  ngOnInit() {
    this.clientService
      .getClient()
      .subscribe((data: Client[])=> {
        this.clients = data;
      });
    this.fournisseurService
      .getFournisseur()
      .subscribe((data: Code[])=> {
        this.codes = data;
      });
  }

  createMachine(data: Machine){
    this.machineService.createMachine(data);
  }

}
