import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Machine} from "./machine.interface";

@Injectable()
export class MachineService {
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) { }
  url = '/api';
  getMachine(){
    return this
      .http
      .get(`${this.url}/machines`);
  }
  getMachineSN(sn){
    return this.http.get('api/machines/edit/'+sn);
  }

  createMachine(data) {
    this.http.post(`${this.url}/machines`, data)
      .subscribe(
        res => {
          console.log(res);
          this.toastr.success('Votre machine a été créer avec succès.', 'Success');
          this.router.navigateByUrl('/machine');
        },
        err => {
          console.log('Error occured:' , err);
          this.toastr.error(err.message, 'Error occured');
        }
      );
  }

  editMachine(data) {
    this.http.post('api/machines/edit/'+data.sn, data)
      .subscribe(res => {console.log(res);
          this.toastr.success('Votre machine a été editée avec succès.', 'Success');
          this.router.navigateByUrl('/machine');
        },
        err => {
          console.log('Error occured:' , err);
          this.toastr.error(err.message, 'Error occured');

      }
    );
  }

  deleteMachine(data) {
    this.http.post(`${this.url}/machines/delete`, data)
      .subscribe(
        res => {
          console.log(res);
          this.toastr.success('Votre machine a été supprimer avec succès.', 'Success');
          this.getMachine();
          },
        err => {
          console.log('Error occured:' , err);
          this.toastr.error(err.message, 'Error occured');

        }
      );
  }
  countByPaint() {
    return this
      .http
      .get( 'api/machines/paint')
      .pipe(map(result => result));
  }
  countMachine() {
    return this.http.get('api/machines/count').pipe(map(result => result));
  }
  errorBySN(data){
    return this.http.get('api/machines/details/'+data.sn+'/'+data.d1+'/'+data.d2).pipe(map(result =>result));

  }
  nexusBySN(data){
    return this.http.get('api/machines/nexus/'+data.sn+'/'+data.d1+'/'+data.d2).pipe(map(result =>result));
  }
  errorByDate(sn){
    return this.http.get('api/machines/error/'+sn).pipe(map(result =>result));
  }
  sensors(data){
    return this.http.get('api/machines/sensors/'+data.sn+'/'+data.d1+'/'+data.d2).pipe(map(result => result));
  }
  clientByID(id){
    return this.http.get('api/machines/edit/client/'+id).pipe(map(result => result));
    console.log(id);
  }
  paintByMCBA(mcba){
    return this.http.get('api/machines/edit/paint/'+mcba).pipe(map(result => result));
  }
  getFrontDoorSensors(data){
    return this.http.get('api/machines/fdoor/'+data.sn+'/'+data.d1+'/'+data.d2).pipe(map(result =>result));
  }
  getLateralDoorSensors(data){
    return this.http.get('api/machines/ldoor/'+data.sn+'/'+data.d1+'/'+data.d2).pipe(map(result =>result));
  }
}
