import { Component, OnInit, OnChanges} from '@angular/core';
import {AuthProcessService, LoggedInGuard, NgxAuthFirebaseUIModule} from "ngx-auth-firebaseui";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private userState: Observable<boolean>;

  constructor(private router: Router, private auth: AuthProcessService) {
    this.userState = this.auth.afa.user.pipe(
      map(res => {
          if(res)
            return true;
          else
            return false;
        }
      )
    );
  }

  ngOnInit() {
    if(this.userState)
      this.goToMachine();
  }
  ngOnChanges(){
    if(this.userState)
      this.goToMachine();
  }
  goToMachine (){
    this.router.navigateByUrl('/machine');
  }
}
