import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {LumiereService} from "../lumiere/lumiere.service";
import {MatTableDataSource, MatPaginator, MatSort, MatDatepickerInputEvent} from "@angular/material";
import {Lumiere} from "../lumiere/lumiere.interface";
import {FormControl} from "@angular/forms";

export interface globalData {
  qtActual: bigint;
  qtTarget: bigint;
  nbDosage: bigint;
}


@Component({
  selector: 'app-lumiere-mcba',
  templateUrl: './lumiere-mcba.component.html',
  styleUrls: ['./lumiere-mcba.component.css']
})


export class LumiereMcbaComponent implements OnInit {
  mcba: string;
  dataSource: MatTableDataSource<globalData>;
  nbElem: number;
  date1 = new Date();
  date2 = new Date();
  data = {'d1':null,'d2':null};
  control1 = new FormControl(this.date1);
  control2 = new FormControl(this.date2);
  model: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: 'ctg',
    offText: 'g',
    disabled: false,
    size: '',
    value: true
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private route: ActivatedRoute, private toastr: ToastrService, public router: Router, private lumiereService: LumiereService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.mcba = params.get("mcba")
    });

    this.lumiereService.getGlobalFromMCBA(this.mcba).subscribe((data: any[])=>{
      //console.log(data);
      //this.gData=data;
      this.dataSource = new MatTableDataSource(data);
      //console.log(this.dataSource.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });

    this.date2.setDate(this.date1.getDate()-7);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();

  }

  addEvent1( event: MatDatepickerInputEvent<Date>) {
    this.date1=event.value;
    // console.log(this.date1);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.lumiereService.getHistory(this.data).subscribe((data: Lumiere[]) =>{
      this.dataSource = new MatTableDataSource(data);
      console.log(this.dataSource);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.date2=event.value;
    //console.log(this.date2);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.lumiereService.getHistory(this.data).subscribe((data: Lumiere[]) =>{
      this.dataSource = new MatTableDataSource(data);
      console.log(this.dataSource);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });

  }

  getUnitAndConvert(value){
    if(this.model.value==true){
      return value;
    }
    else{
      return value/100;
    }
  }

}
