import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {Router} from "@angular/router";
import { map } from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import {AuthProcessService, LoggedInGuard, NgxAuthFirebaseUIModule} from "ngx-auth-firebaseui";
import {AngularFireAuthGuard} from "@angular/fire/auth-guard";
import {AngularFireAuth} from "@angular/fire/auth";
import {FirebaseAuth} from "@angular/fire";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  userState: Observable<any>;

  constructor(private breakpointObserver: BreakpointObserver, private toastr: ToastrService, private router: Router, private auth: AuthProcessService) {
    router.events.subscribe(
      function (){
        toastr.clear();
      });
      this.userState = this.auth.afa.user.pipe(
        map(res => {
          if(res)
            return true;
          else
            return false;
          }
        )
      );
  }
  goToClient (){
    this.router.navigateByUrl('/client');
  }
  goToMachine (){
    this.router.navigateByUrl('/machine');
  }
  goToMenu (){
    this.router.navigateByUrl('/');
  }
  goToFournisseur (){
    this.router.navigateByUrl('/fournisseur');
  }
  goToChart (){
    this.router.navigateByUrl('/chart');
  }
  goToLumiere (){
    this.router.navigateByUrl('/lumiere');
  }


}
