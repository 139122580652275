import { Component, OnInit } from '@angular/core';
import {MachineService} from "../machines/machine.service";
import {Machine} from "../machines/machine.interface";
import {ClientService} from "../clients/client.service";
import {Client} from "../clients/client.interface";
import {FournisseurService} from "../fournisseurs/fournisseur.service";
import {ActivatedRoute} from "@angular/router";
import {FormControl} from "@angular/forms";


export interface Code {
  MCBA_PEINTURES: string;
  NomFournisseur_PEINTURES: string;
}
@Component({
  selector: 'app-edit-machine',
  templateUrl: './edit-machine.component.html',
  styleUrls: ['./edit-machine.component.css']
})
export class EditMachineComponent implements OnInit {

  machines: Machine = {
    sn: '',
    nomclient: '',
    date: '',
    fournisseur: '',

  };

  clients: Client[];
  codes: Code[];
  sn: string;

  dateControl = new FormControl(this.machines.date);
  date: Date;
  nomclient: number;
  peinture:string;

  constructor(private route: ActivatedRoute,private machineService: MachineService, private clientService: ClientService, private fournisseurService: FournisseurService) { }



  ngOnInit() {
    this.clientService
      .getClient()
      .subscribe((data: Client[])=> {
        this.clients = data;
        console.log(data);
      });
    this.fournisseurService
      .getFournisseur()
      .subscribe((data: Code[])=> {
        this.codes = data;
        console.log(data);
      });
    this.route.paramMap.subscribe(params =>{
      this.sn = params.get("sn");
      console.log(this.sn);

    });
    this.machineService
      .getMachineSN(this.sn)
      .subscribe((data: Machine)=>{
        this.machines.sn = data["edit"].map(res => res.SN_MACHINES).toString();
        this.date = new Date(data["edit"].map(res => res.DateMiseService_MACHINES));
        this.machines.date = this.date.toJSON().slice(0,10);
        this.machines.fournisseur = data["edit"].map(res => res.peintures_mcba_peintures).toString();
        this.machines.nomclient = data["edit"].map(res => res.clients_id_clients).toString();
        //this.machines.Nomclient ="Fillon Tech";
        console.log(data,this.machines);
      });
    this.machineService.clientByID(this.machines.nomclient)
      .subscribe( (data: any)=>{
        this.nomclient=data["edit"].map(res => res.Nom_CLIENTS);
        console.log(data,this.machines.nomclient,this.nomclient);
      });
    this.machineService.paintByMCBA(this.machines.fournisseur)
      .subscribe( (data: any)=>{
        this.peinture=data["edit"].map(res => res.NomFournisseur_PEINTURES);
        console.log(data);
      });

  }

  editMachine(data: Machine){
    this.machineService.editMachine(data);

  }

}
