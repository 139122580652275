import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Machine} from "../machines/machine.interface";
import {Lumiere} from './lumiere.interface';
import {MachineService} from "../machines/machine.service";
import {Router} from "@angular/router";
import {LumiereService} from "./lumiere.service";
import {MatDatepickerInputEvent, MatPaginator, MatSort, MatTableDataSource, MatTableModule} from "@angular/material";
import {Errors, Nexus} from "../machines-details/machines-details.component";
import {NgToggleModule} from '@nth-cloud/ng-toggle';
import html2canvas from "html2canvas";
import * as jspdf from 'jspdf';

@Component({
  selector: 'app-lumiere',
  templateUrl: './lumiere.component.html',
  styleUrls: ['./lumiere.component.css']
})
export class LumiereComponent implements OnInit {

  date1 = new Date();
  date2 = new Date();
  data = {'d1':null,'d2':null};
  control1 = new FormControl(this.date1);
  control2 = new FormControl(this.date2);
  datesShortened: any[];
  lumiere: Lumiere[];
  displayedColumns = ["peintures_mcba_peintures","SN_MACHINES","actual","target","nbDosage"];
  dataSource: MatTableDataSource<Lumiere>;
  nbElem: number;
  model: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: 'ctg',
    offText: 'g',
    disabled: false,
    size: '',
    value: true
  };


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private lumiereService: LumiereService,public router: Router) { }

  ngOnInit():void {
    this.date2.setDate(this.date1.getDate()-7);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.lumiereService.getHistory(this.data).subscribe((data: Lumiere[]) =>{
      this.dataSource = new MatTableDataSource(data);
      console.log(this.dataSource);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });

    console.log(this.dataSource);
  }

  applyFilter(filterValue: string) {
    /*let strArray=filterValue.split(' ');
   for(let i=0; i<strArray.length; i++){
      this.dataSource.filter      strArray[i].toLowerCase();
    }*/
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  addEvent1( event: MatDatepickerInputEvent<Date>) {
    this.date1=event.value;
    // console.log(this.date1);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.lumiereService.getHistory(this.data).subscribe((data: Lumiere[]) =>{
      this.dataSource = new MatTableDataSource(data);
      console.log(this.dataSource);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.date2=event.value;
    //console.log(this.date2);
    this.data.d1=this.date1.toISOString(); this.data.d2=this.date2.toISOString();
    this.lumiereService.getHistory(this.data).subscribe((data: Lumiere[]) =>{
      this.dataSource = new MatTableDataSource(data);
      console.log(this.dataSource);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.nbElem = data.length;
    });

  }
  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  }

  getUnitAndConvert(value){
    if(this.model.value==true){
      return value;
    }
    else{
      return value/100;
    }
  }

  goToMCBA(mcba){
    this.router.navigateByUrl('/lumiere/'+mcba);
  }

  public captureScreen() {
    const data = document.getElementById('pdf');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(this.getDate(0)+'_detail_.pdf');
    });
  }
}
