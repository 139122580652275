import {Component, OnInit, ViewChild} from '@angular/core';
import {FournisseurService} from './fournisseur.service';
import {Fournisseur} from './fournisseur.interface';
import {Router} from "@angular/router";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {Machine} from "../machines/machine.interface";

@Component({
  selector: 'app-fournisseurs',
  templateUrl: './fournisseurs.component.html',
  styleUrls: ['./fournisseurs.component.css']
})
export class FournisseursComponent implements OnInit {

  fournisseurs: Fournisseur[];
  dataSource: MatTableDataSource<Fournisseur>;
  displayedColumns = ["Code","Ligne","EDIT","DELETE"]
  nbElem: number;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private fournisseurService: FournisseurService, private router: Router) {
  }

  ngOnInit() {
    this.fournisseurService
      .getFournisseur()
      .subscribe((data: Fournisseur[]) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.nbElem = data.length;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getDate(string){ // return the current date if string==0, return the string param has a Date otherwise
    if(string==0)
      var date = new Date();
    else
      var date = new Date(string);
    var options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
    // return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return date.toLocaleDateString('fr-FR',options);
  }

  goToAddFournisseur(){
    this.router.navigateByUrl('/ajout-fournisseur');
  }

  deleteFournisseur(data: Fournisseur) {
    this.fournisseurService.deleteFournisseur(data);
    this.ngOnInit();
  }


}
