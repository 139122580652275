import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {InjectableCompiler} from "@angular/compiler/src/injectable_compiler";

@Injectable()
export class LumiereService {
  constructor(private http: HttpClient, private  toastr: ToastrService, private router: Router){}
  url = 'api';

  getHistory(data){
    console.log(data);
    return this.http.get('api/history/quantity/'+data.d1+'/'+data.d2).pipe(map(result => result));
  }
  getGlobalFromMCBA(mcba){
    console.log(mcba);
    return this.http.get('api/history/'+mcba+'/global').pipe(map(result => result));
  }

}
